import { ExternalLink, NextLink } from '@/features/shared/elements/Link'
import { Body } from '@shipt/design-system-typography'
import { type FooterData } from '@/features/shared/components/Footers/MarketingFooter/types'

type Props = {
  links: FooterData['sections'][number]['links']
}

export const MarketingFooterLinks = ({ links }: Props) => (
  <>
    {links.map(({ name, url, target, external }) =>
      external ? (
        <ExternalLink key={name} href={url} target={target} $underline="none">
          <Body concept="accent">{name}</Body>
        </ExternalLink>
      ) : (
        <NextLink key={name} href={url} target={target} prefetch={false}>
          <Body concept="accent">{name}</Body>
        </NextLink>
      )
    )}
  </>
)
